<template>
  <div class="draftCard">
    <div class="draftCard__name">
      <Avatar :agent="agent" size="small" />
      <span>{{ agent.lastName }}, {{ agent.firstName }}</span>
    </div>
    <div>
      <span class="draftCard__date">Last edited: </span>
      <span class="draftCard__dateLabel">{{ prettyDate(draft.coachedDate) }}</span>
    </div>
    <a class="draftCard__editLink" @click="edit">Continue editing <i class="icon">arrow_forward</i></a>
    <div class="draftCard__status">
      <div class="draftCard__indicator"></div>
      <span class="draftCard__statusText">In Progress</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Avatar from '../Avatar.vue';
import { prettyDate } from '@/utils/date-helpers';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  draft: Object
});

const agent = computed(() => ({
  firstName: props.draft?.agentFirstName || '',
  lastName: props.draft?.agentLastName || ''
}));

const edit = () => {
  router.push({
    name: 'PerformAssessment',
    params: { agentId: props.draft.agentId },
    query: { draftId: props.draft.coachingSessionId }
  });
};
</script>

<style lang="scss">
.draftCard {
  position: relative;
  background-color: var(--white);
  box-shadow: var(--elevation-low);
  padding: 32px 48px 40px 48px;
  border-radius: 6px;
  border: var(--border-subtle);
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__name {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
  }

  &__date {
    color: var(--black-trans-light);
    font-weight: var(--font-weight-medium);
  }

  &__dateLabel {
    color: var(--black-trans);
    font-weight: var(--font-weight-bold);
  }

  &__editLink {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--accent);
    cursor: pointer;
    font-weight: var(--font-weight-medium);
  }

  &__status {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 8px;
    top: 42px;
    right: 48px;
  }

  &__indicator {
    height: 12px;
    width: 12px;
    min-width: 12px;
    border-radius: 6px;
    background-color: var(--assessment-yellow);
  }

  &__statusText {
    text-transform: uppercase;
    color: var(--black-trans);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-small);
    letter-spacing: 1px;
  }
}
</style>
