import { createApp, markRaw } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { vfmPlugin } from 'vue-final-modal';
import 'vue-components/dist/vue-components.css';
import '../styles/styles.scss';
import { LAUNCHPAD_IFRAME_ID } from '@/resources';
import { LAUNCHPAD_URL } from './application.config';
import { liftoff } from './launchpad';

/**************************************************************************************
 * Initialize the app.
 **************************************************************************************/
const initApp = () => {
  /*******************************************
   * Store
   *******************************************/
  // Create the store before creating the app to make the store is always available to the app.
  const pinia = createPinia();
  // Expose the router to the store.
  pinia.use(({ store }) => {
    store.router = markRaw(router);
  });

  /*******************************************
   * Create the app
   *******************************************/
  let app = createApp(App);

  // Temporary setting. This config is default in future versions of vue.
  app.config.unwrapInjectedRef = true;

  // Use the pinia store.
  app.use(pinia);

  /*******************************************
   * Register global components
   *******************************************/

  // Vue Final Modal, base for all our modals and dialogs. - https://v3.vue-final-modal.org/
  app.use(vfmPlugin);

  /*******************************************
   * Router
   *******************************************/
  app.use(router);

  /*******************************************
   * Mount the app
   *******************************************/
  const containerSelector = '#app';

  // check if app has been mounted already
  const mountPoint = document.querySelector(containerSelector);

  if (mountPoint && mountPoint.__vue_app__ !== undefined) {
    // Set the existing mount point to 'app'.
    app = mountPoint.__vue_app__._instance.proxy;
  } else {
    // Mount 'app' (App.vue) as root component.
    app.mount(containerSelector);
  }
};

/**************************************************************************************
 * Auth - Launchpad
 **************************************************************************************/
liftoff(LAUNCHPAD_IFRAME_ID, LAUNCHPAD_URL, initApp);
