<template>
  <!-- Header -->
  <header v-if="showHeader">
    <AppHeader
      title="Develop"
      :userDisplayName="loggedInUser?.memberName || ''"
      :navOptions="navOptions"
      :selectedNavLink="activeLinkFirstSegment"
      :appMenuItems="appMenuItems"
      :userMenuItems="userMenuItems"
      @nav="goToLink"
      @logout="logout"
      @userMenuClick="handleUserMenuClick($event)"
      @goToApp="goToApp"
    />
  </header>

  <!-- Main section (router outlet) -->
  <main>
    <NotificationSystem ref="notify" />
    <Loader />
    <ConfirmDialog />
    <router-view />
  </main>
</template>

<script setup>
import { ref, computed, provide, onMounted } from 'vue';
import { AppHeader, NotificationSystem } from 'vue-components';
import { useAuthStore, useDraftsStore } from '@/stores';
import { useRoute, useRouter } from 'vue-router';
import { EXTERNAL_LINKS } from './application.config';
import { Loader, ConfirmDialog } from '@/components';

const defaultNavOptions = [
  { displayName: 'Dashboard', route: { path: '/dashboard', name: 'ManagerDash' } },
  { displayName: 'People', route: { path: '/people', name: 'People' } },
  { displayName: 'Insights', route: { path: '/insights', name: 'Insights' } }
];
let permittedNavOptions = ref([]);

const appMenuItems = ref([
  { displayText: 'Develop', event: EXTERNAL_LINKS.DEVELOP },
  { displayText: 'Assess', event: EXTERNAL_LINKS.ASSESS },
  { displayText: 'CX Platform', event: EXTERNAL_LINKS.CXP }
]);

let notify = ref(null);

provide('notify', notify);

const authStore = useAuthStore();
const draftsStore = useDraftsStore();
const router = useRouter();
const route = useRoute();

const loggedInUser = ref({});

const userMenuItems = computed(() => {
  const count = draftsStore.drafts.length ? ` (${draftsStore.drafts.length})` : '';
  return [{ id: 'mydrafts', displayText: `My Drafts${count}`, event: 'goToMyDrafts', icon: 'drafts' }];
});
const showHeader = computed(() => route.name !== 'Login');
const activeLinkFirstSegment = computed(() => `/${route.path.split('/')[1]}`);
const navOptions = computed(() => [...defaultNavOptions, ...permittedNavOptions.value]);

const goToLink = route => {
  router.push({ name: route.name });
};

const handleUserMenuClick = event => {
  const handlers = {
    goToMyDrafts: () => {
      router.push({ name: 'MyDrafts' });
    }
  };

  handlers[event]();
};

const goToApp = link => {
  location.assign(link);
};

const logout = () => {
  authStore.logout();
};

onMounted(() => {
  authStore.checkPermission('Coaching', 'admin').then(canAdmin => {
    if (canAdmin) {
      permittedNavOptions.value = [{ displayName: 'Admin', route: { path: '/admin', name: 'Admin' } }];
    }
  });

  authStore.checkPermission('Admin Tool', 'read').then(canAdmin => {
    if (canAdmin) {
      appMenuItems.value.push({ displayText: 'Compliance Behaviors', event: EXTERNAL_LINKS.COMPLIANCE });
    }
  });

  authStore.getLoggedInUser().then(user => {
    loggedInUser.value = user;
  });
});

// Expose for testing
defineExpose({ activeLinkFirstSegment, userMenuItems, handleUserMenuClick, showHeader });
</script>

<style lang="scss">
main {
  position: relative;
}
</style>
