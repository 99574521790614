export const DEFAULT_CONFIRMATION = {
  title: 'Confirm Action',
  message: 'Are you sure you want to perform this action?',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  confirmButtonStyle: ''
};

export const DELETE_COMMENT_CONFIRMATION = {
  title: 'Delete Comment',
  message: 'Are you sure you want to delete this comment?',
  confirmText: 'Delete',
  cancelText: 'Cancel',
  confirmButtonStyle: 'delete'
};

export const CANCEL_ASSESSMENT_CONFIRMATION = {
  title: 'Cancel Assessment',
  message: [
    'This action will cancel the current assessment and all progress will be lost.',
    'Are you sure you want to cancel the assessment?'
  ],
  confirmText: 'Cancel Assessment',
  confirmButtonStyle: 'delete',
  cancelText: 'Stay Here'
};

export const UNDO_DRAFT_CHANGES_CONFIRMATION = {
  title: 'Undo Draft Changes',
  message: [
    'This action will cancel editing of the current assessment and undo all changes to this draft.',
    'Are you sure you want to undo changes?'
  ],
  confirmText: 'Undo Changes',
  confirmButtonStyle: 'delete',
  cancelText: 'Stay Here'
};
