<template>
  <div class="step">
    <div class="step__title">
      <div class="step__title--firstLine">Building an Assessment for:</div>
      <Avatar :agent="assessment.agent" />
    </div>
    <div class="step__subtitle">Now, set some outcome objectives</div>

    <div class="step__player">
      <EvalPlayer :evaluations="selectedEvaluations" :comments="assessment.comments" />
    </div>

    <div class="step__heading">Outcomes / Outputs</div>
    <div class="outcomes__list">
      <OutcomeCard
        v-for="outcome in outcomes"
        :key="outcome.id"
        :outcome="outcome"
        :allowRemove="true"
        class="selected"
        @remove="removeOutcome"
      />
      <AddOutcomeButton @click="showDialog = true" />
    </div>

    <div class="step__actions">
      <TpgButton flat @click="$emit('back')"><i class="icon">arrow_back</i>Back</TpgButton>
      <TpgButton @click="$emit('next')">Next Section<i class="icon">arrow_forward</i></TpgButton>
    </div>
  </div>
  <AddOutcomeDialog v-model="showDialog" @cancel="showDialog = false" @addOutcomes="addSelectedOutcomes" />
</template>

<script>
import { TpgButton } from 'vue-components';
import EvalPlayer from '../../EvalPlayer/EvalPlayer.vue';
import Avatar from '../../Avatar.vue';
import AddOutcomeButton from './AddOutcomeButton.vue';
import AddOutcomeDialog from './AddOutcomeDialog.vue';
import OutcomeCard from './OutcomeCard.vue';

export default {
  // eslint-disable-next-line
  name: 'Outcomes',
  emits: ['back', 'next'],
  components: {
    TpgButton,
    EvalPlayer,
    Avatar,
    AddOutcomeButton,
    AddOutcomeDialog,
    OutcomeCard
  },
  props: {
    assessment: Object
  },
  data: () => ({
    showDialog: false,
    outcomes: [],
    selectedEvaluations: []
  }),
  methods: {
    addOutcomes() {},
    removeOutcome() {},
    addSelectedOutcomes(outcomeIds) {
      this.addOutcomes(outcomeIds);
      this.showDialog = false;
    }
  }
};
</script>

<style lang="scss">
.outcomes__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 16px;
  margin-bottom: 40px;
}
</style>
