import { HttpService as http } from './HttpService';

/**
 * Class with static methods to assess interaction related API endpoints
 */
export class InteractionService {
  /**
   * Requests an interaction for the given interaction id
   * @param {number} interactionId Interaction id
   * @returns Interaction by given interaction id
   */
  static getInteractionById(interactionId) {
    return http.get(`interactions/${interactionId}`);
  }

  /**
   * Requests an evaluation score for the given interaction id
   * @param {number} interactionId Intearction id
   * @returns Evaluation score for the given interaction id
   */
  static getEvaluationByInteractionId(interactionId) {
    return http.get(`interactions/${interactionId}/score`);
  }
}
