<template>
  <template v-if="loadingMedia">
    <MediaPlayerLoading />
    <div class="behaviorsMedia__evalButtons">
      <div v-for="(e, i) in evals" :key="i" class="behaviorsMedia__evalButtons--loading"></div>
    </div>
  </template>
  <template v-else>
    <MediaPlayer
      v-if="activeMedia && !activeMedia.deleted"
      :audioStream="audioStream"
      :media="activeMedia"
      :markers="activeEvalComments"
      :hideSkipSilenceToggle="true"
      @addCommentAtTimestamp="addCommentAtTimestampHandler"
      @playbackError="notifyPlaybackError"
    />
    <div v-else class="behaviorsMedia__noMedia">
      <MediaPlayerPlaceholder />
      <div v-if="activeMedia?.deleted" class="behaviorsMedia__noMediaText">
        <span>This media has been deleted.</span>
      </div>
      <div v-else-if="activeEval" class="behaviorsMedia__noMediaText">
        <span>No media available for this evaluation. Recording ID:</span>
        <span class="behaviorsMedia__recordingId">{{ activeEval.recordingId }}</span>
      </div>
    </div>
    <div v-if="activeEval" class="behaviorsMedia__evalButtons">
      <EvalButton
        v-for="e in evals"
        :key="e.evaluationId"
        :evaluation="e"
        :class="{ selected: e.evaluationId === activeEval.evaluationId }"
        @click="setActiveEval(e)"
      />
    </div>
  </template>
</template>

<script>
import { MediaPlayerLoading, MediaPlayer, MediaPlayerPlaceholder } from 'vue-components';
import EvalButton from './EvalButton.vue';
import { QUALITYCARE_URL } from '@/application.config';
import { URLS } from '@/resources';

export default {
  name: 'BehaviorsMedia',
  components: {
    MediaPlayerLoading,
    MediaPlayer,
    MediaPlayerPlaceholder,
    EvalButton
  },
  inject: ['notify'],
  data: () => ({
    mediaStreamUrl: `${QUALITYCARE_URL}${URLS.MEDIA_STREAM}`
  }),
  props: {
    evals: Array,
    media: Object,
    loadingMedia: Boolean,
    comments: Array,
    timestamps: [Array, Object],
    activeEval: Object
  },
  emits: ['addCommentAtTimestamp', 'activateEval'],
  computed: {
    activeMedia() {
      return this.activeEval ? this.media[this.activeEval.mediaDocumentId] : undefined;
    },
    activeEvalComments() {
      const comments = this.comments?.filter(c => c.evaluationId === this.activeEval?.evaluationId);

      if (this.timestamps && this.timestamps.length) {
        return comments?.concat(this.timestamps);
      }

      return comments;
    },
    audioStream() {
      return this.activeMedia && !this.activeMedia.deleted
        ? new Audio(`${this.mediaStreamUrl}/${this.activeMedia.documentId}`)
        : null;
    }
  },
  methods: {
    setActiveEval(e) {
      this.$emit('activateEval', e);
    },
    addCommentAtTimestampHandler(timeInMedia) {
      this.$emit('addCommentAtTimestamp', { timeInMedia, evaluationId: this.activeEval.evaluationId });
    },
    notifyPlaybackError() {
      this.notify.error('There was a problem loading this media file.');
    }
  },
  mounted() {
    this.setActiveEval(this.evals[0]);
  }
};
</script>

<style lang="scss">
.behaviorsMedia {
  &__noMedia {
    position: relative;
    height: 146px;
    width: 100%;
  }

  &__noMediaText {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 98px; // Waveform placeholder height.
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-weight-bold);
  }

  &__recordingId {
    color: var(--accent);
    margin-left: 4px;
  }

  &__evalButtons {
    display: flex;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 40px;

    &--loading {
      height: 33px;
      width: 250px;
      border-radius: 18px;
      background-color: var(--assess-field-bg-color);
    }
  }
}
</style>
