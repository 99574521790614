import { defineStore } from 'pinia';
import { AgentService } from '@/services/AgentService';
import { useAuthStore, useDashboardStore, useCoachingStore } from '.';

const initAgentTableState = {
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  searchString: '',
  sortOrder: 'nextAssessed',
  asc: true,
  parentAgentId: null
};

export const useAgentStore = defineStore('agent', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    selectedAgentSourceId: JSON.parse(localStorage.getItem('selectedAgentSourceId')) || null,
    selectedProgramId: JSON.parse(localStorage.getItem('selectedProgramId')) || null,
    loadingAgentSources: false,
    agentSources: null,
    loadingAgents: false,
    agents: [],
    loadingSupervisors: false,
    supervisors: [],
    agentTableState: { ...initAgentTableState }
  }),

  /*************************************************
   * GETTERS
   *************************************************/

  getters: {
    selectedSourcePrograms: state => {
      return state.agentSources?.find(source => source.agentSourceId === state.selectedAgentSourceId)?.programs || null;
    },
    selectedProgram: state => {
      return state.selectedSourcePrograms?.find(program => program.programId === state.selectedProgramId) || null;
    }
  },

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Fetch all available agent sources for this user.
     */
    async fetchAgentSources() {
      this.loadingAgentSources = true;
      const loggedInUser = await useAuthStore().getLoggedInUser();
      return AgentService.getAgentSources(loggedInUser.memberId)
        .then(response => {
          this.agentSources = this.addLocalIdsToPrograms(response.data);
        })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          this.loadingAgentSources = false;
        });
    },
    /**
     * Adds a localId to each program in the list of agent sources / programs to give similar programs a unique identifier.
     * @param {Array} sources
     * @returns
     */
    addLocalIdsToPrograms(sources) {
      sources.forEach(source => {
        source.programs.forEach(program => {
          program.localId = `${source.agentSourceId}-${program.programId}`;
        });
      });
      return sources;
    },
    /**
     * Sets the user's agent source and program selections in state and saves them to local storage for recall across sessions.
     * @param {number} agentSourceId The user selected agent source ID.
     * @param {number} programId The user selected program ID.
     * @returns {Promise | void}
     */
    setSelectedIds({ agentSourceId, programId }) {
      localStorage.setItem('selectedAgentSourceId', agentSourceId);
      this.selectedAgentSourceId = agentSourceId;

      // Prevent invalid program id from being stored in local storage
      if (isNaN(programId)) {
        programId = null;
      } else {
        localStorage.setItem('selectedProgramId', programId);
        this.selectedProgramId = programId;
      }
      // Reset the coaching store to clear out compendium behaviors, call outcomes, enterprise focuses, etc.
      useCoachingStore().$reset();
      // Reset any agents and agent table state in the store.
      this.agents = [];
      this.resetAgentTableState();
      if (agentSourceId && programId) {
        useDashboardStore().hydrateDashboard(agentSourceId, programId);
        this.getAgents();
        this.getSupervisors();
      }
    },
    /**
     * Sets the agent table state back to an init value.
     */
    resetAgentTableState() {
      this.agentTableState = { ...initAgentTableState };
    },
    /**
     * Gets agents for the given agent source id that match the passed params.
     * @param {number} pageNumber The page number to request for a paginated list of results.
     * @param {number} pageSize The size of each page in a paginated list of results.
     * @param {string} searchString A search string to apply to the query.
     * @returns {Promise}
     */
    getAgents(pageNumber, pageSize, searchString, sortOrder, asc, parentAgentId) {
      this.loadingAgents = true;

      // Use the args passed to this function or their corresponding state values if undefined.
      pageNumber = pageNumber !== undefined ? pageNumber : this.agentTableState.pageNumber;
      pageSize = pageSize !== undefined ? pageSize : this.agentTableState.pageSize;
      searchString = searchString !== undefined ? searchString : this.agentTableState.searchString;
      sortOrder = sortOrder !== undefined ? sortOrder : this.agentTableState.sortOrder;
      asc = asc !== undefined ? asc : this.agentTableState.asc;
      parentAgentId = parentAgentId !== undefined ? parentAgentId : this.agentTableState.parentAgentId;

      return AgentService.getAgents(
        this.selectedAgentSourceId,
        this.selectedProgramId,
        pageNumber,
        pageSize,
        searchString,
        sortOrder,
        asc,
        parentAgentId
      )
        .then(response => {
          // Update agent table state params that are not included in the response.
          // Do this after the query so that state always reflects the last successful query.
          this.agentTableState.pageSize = pageSize;
          this.agentTableState.searchString = searchString;
          this.agentTableState.sortOrder = sortOrder;
          this.agentTableState.asc = asc;
          this.agentTableState.parentAgentId = parentAgentId;
          this.handleAgentResponse(response);
        })
        .catch(error => {
          this.handleAgentError(error);
        });
    },
    /**
     * Gets a single agent from state, or fetches it from the endpoint and patches it in state.
     * @param {number} agentId The id of the agent to fetch.
     * @returns {Promise<Agent | null>} The desired agent or null if it cannot be found in state or through an api request.
     */
    getAgentById(agentId) {
      const agentInState = this.agents.find(a => a.agentId === agentId);
      if (agentInState) {
        return Promise.resolve(agentInState);
      } else {
        this.loadingAgents = true;
        return AgentService.getAgent(agentId)
          .then(response => {
            const agent = response.data;
            this.loadingAgents = false;
            this.agents = [...this.agents, agent];
            return agent;
          })
          .catch(error => {
            this.handleAgentError(error);
            return null;
          });
      }
    },
    /**
     * Performs a get agents request with an updated pageSize param.
     * @param {number} pageSize The new page size for the paginated query.
     * @returns {Promise}
     */
    updatePageSize(pageSize) {
      return this.getAgents(1, pageSize);
    },
    /**
     * Performs a get agents request with an updated searchString param.
     * @param {string} searchString The new search string to apply to the query.
     * @returns {Promise}
     */
    updateSearchString(searchString) {
      return this.getAgents(undefined, undefined, searchString);
    },
    /**
     * Performs a get agents request with a new parent agent id.
     * @param {number} parentAgentId The agentId of the supervisor to filter by.
     * @returns {Promise}
     */
    filterBySupervisor(parentAgentId) {
      return this.getAgents(undefined, undefined, undefined, undefined, undefined, parentAgentId);
    },
    /**
     * Performs a get agents request with an updated sort order.
     * @param {'name' | 'nextAssessed' | 'lastAssessed'} sortOrder A string key to pass as the sort order.
     * @returns {Promise}
     */
    updateSortOrder(sortOrder) {
      const asc = sortOrder === this.agentTableState.sortOrder ? !this.agentTableState.asc : true;
      return this.getAgents(undefined, undefined, undefined, sortOrder, asc);
    },
    /**
     * Gets the previous page of results for the current query.
     * @returns {Promise}
     */
    previousPage() {
      if (this.agentTableState.hasPreviousPage) {
        return this.getAgents(this.agentTableState.currentPage - 1);
      }
    },
    /**
     * Gets the next page of results for the current query.
     * @returns {Promise}
     */
    nextPage() {
      if (this.agentTableState.hasNextPage) {
        return this.getAgents(this.agentTableState.currentPage + 1);
      }
    },
    /**
     * Gets a specific page of results for the current query.
     * @returns {Promise}
     */
    goToPage(pageNumber) {
      return this.getAgents(pageNumber);
    },
    /**
     * Handles a response from the agents endpoint and updates agents, agent table state, and loading agents.
     * @param {AxiosResponse} response Axios response object.
     */
    handleAgentResponse(response) {
      const { items, pageIndex, totalPages, hasPreviousPage, hasNextPage } = response.data;
      this.agents = items;
      this.agentTableState = {
        ...this.agentTableState,
        currentPage: pageIndex,
        totalPages,
        hasPreviousPage,
        hasNextPage
      };
      this.loadingAgents = false;
    },
    /**
     * Handles an error from an agents endpoint api call.
     * @param {AxiosError} error Error response from Axios
     */
    handleAgentError(error) {
      this.loadingAgents = false;
      throw error?.response?.data || error?.message;
    },
    /**
     * Gets supervisors for the selected agent source.
     * @returns {Promise | void}
     */
    getSupervisors() {
      this.loadingSupervisors = true;
      return AgentService.getSupervisors(this.selectedAgentSourceId, this.selectedProgramId)
        .then(response => {
          this.supervisors = response.data?.items;
        })
        .finally(() => {
          this.loadingSupervisors = false;
        });
    }
  }
});
