import { defineStore } from 'pinia';
import { MediaService } from '@/services/MediaService';
import { prettyTimeString } from '@/utils/time';
import { getMarkerType } from '../utils/marker-type-helpers';
import { useInteractionStore } from './useInteractionStore';

export const useMediaStore = defineStore('media', {
  /*********************************
   * STATE
   *********************************/

  state: () => ({
    media: {},
    waveforms: {},
    timestamps: {},
    loadingMedia: false
  }),

  /*********************************
   * ACTIONS
   *********************************/

  actions: {
    /**
     * Returns the media with the request documentId from state, or fetches it from the api.
     * @param {number} documentId The documentId of the media to return.
     * @param {boolean} updateLoadingState Whether this action should handling updating loadingMedia state.
     * @returns {Promise<Media>}
     */
    getMediaById(documentId, updateLoadingState = true) {
      if (this.media[documentId]) {
        return Promise.resolve(this.media[documentId]);
      } else {
        updateLoadingState ? (this.loadingMedia = true) : undefined;
        return this.getMediaWaveformData(documentId)
          .then(data => {
            this.media[documentId] = data;

            if (this.media[documentId] && !this.media[documentId].documentId) {
              this.media[documentId].documentId = documentId;
            }
            return data;
          })
          .catch(error => {
            throw error;
          })
          .finally(() => {
            updateLoadingState ? (this.loadingMedia = false) : undefined;
          });
      }
    },
    /**
     * Gets all the media associated with a selection of evaluations.
     * @param {Evaluation[]} evaluations The evaluations for which to fetch media, if available.
     */
    async getMediaForEachEvaluation(evaluations) {
      if (evaluations && evaluations.length > 0) {
        this.loadingMedia = true;
        for (const evaluation of evaluations) {
          const documentId = evaluation.mediaDocumentId;
          if (documentId) {
            await this.getMediaById(documentId, false);
            const interaction = (await useInteractionStore().getInteractionById(Number(evaluation.interactionId)))?.data;
            this.media[documentId].mediaDocumentIsDeleted = evaluation.mediaDocumentIsDeleted;
            this.media[documentId].duration = interaction?.interactionLengthInMilliseconds;
          }
        }
        this.loadingMedia = false;
      }
    },
    /**
     * Retrieves the timestamps for a given interaction ID and returns them
     * @param interactionId The interaction ID to retrieve timestamps for
     */
    async getMediaTimestamps(interactionId) {
      let timestamps = [];

      if (!interactionId) {
        return this.timestamps;
      }

      try {
        const response = await MediaService.getMediaTimestamps(interactionId);
        timestamps = response.data?.timestamps;
      } catch (error) {
        throw error.response?.data || error.message;
      }

      this.timestamps = timestamps?.map(t => {
        return {
          timeInMedia: prettyTimeString(t.timestamp), // in seconds
          commentTitle: t.behavior,
          description: t.response,
          isDefect: t.defect,
          markerType: getMarkerType(t.defect)
        };
      });

      return this.timestamps;
    },
    /**
     * Retrieves the Waveform data for a given document ID and returns it
     * @param documentId The document ID to retrieve waveform data for
     */
    async getMediaWaveformData(documentId) {
      let media = {};

      try {
        const response = await MediaService.getMediaWaveformData(documentId);
        media = response.data;
      } catch (error) {
        throw error?.response?.data || error?.message;
      }

      return media;
    }
  }
});
