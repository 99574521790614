<template>
  <div>
    <h2 class="dashboardDrafts__header">
      <span>Drafts</span>
      <router-link class="dashboardDrafts__link" :to="{ name: 'MyDrafts' }">{{ viewAll }}</router-link>
    </h2>
    <template v-if="loadingDrafts">
      <div class="dashboardDrafts">
        <DraftCardLoading />
        <DraftCardLoading />
      </div>
    </template>
    <template v-else>
      <div v-if="dashboardDrafts.length" class="dashboardDrafts">
        <template v-for="draft in dashboardDrafts" :key="draft.coachingSessionId">
          <DraftCard :draft="draft" />
        </template>
      </div>
      <div v-else class="dashboardDrafts__none">
        <h5>In progress coaching sessions will appear here. There are currently none to display.</h5>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useDraftsStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { onMounted, computed } from 'vue';
import DraftCard from './DraftCard.vue';
import DraftCardLoading from './DraftCardLoading.vue';

const draftsStore = useDraftsStore();
const { loadingDrafts, dashboardDrafts, drafts } = storeToRefs(draftsStore);

const viewAll = computed(() => (drafts.value.length ? `View all (${drafts.value.length})` : 'View all'));

onMounted(() => {
  if (!dashboardDrafts.value.length) {
    draftsStore.getDrafts();
  }
});

// Expose for testing.
defineExpose({ viewAll });
</script>

<style lang="scss">
.dashboardDrafts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  &__header {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  &__link {
    font-size: var(--font-size-small);
    color: var(--accent);
    font-weight: var(--font-weight-normal);
    margin-top: 4px;
  }

  &__none {
    height: 208px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
