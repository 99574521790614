<template>
  <div class="assessment">
    <div class="assessment__container">
      <template v-if="assessment">
        <SelectEvals
          v-if="currentStep === 1"
          :assessment="assessment"
          @back="back()"
          @saveAsDraft="saveAsDraft()"
          @next="next()"
        />
        <Behaviors
          v-if="currentStep === 2"
          :assessment="assessment"
          @back="back()"
          @saveAsDraft="saveAsDraft()"
          @next="next()"
        />
        <FinalComment
          v-if="currentStep === 3"
          :assessment="assessment"
          @back="back()"
          @saveAsDraft="saveAsDraft()"
          @next="next()"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useDocStore, useAssessmentStore, useDashboardStore, useConfirmDialogStore } from '@/stores';
import { SelectEvals, Behaviors, FinalComment } from '@/components/AssessmentSteps';
import { CANCEL_ASSESSMENT_CONFIRMATION, UNDO_DRAFT_CHANGES_CONFIRMATION } from '@/resources';

export default {
  // eslint-disable-next-line
  name: 'Assessment',
  components: {
    SelectEvals,
    Behaviors,
    FinalComment
  },
  inject: ['notify'],
  data: () => ({
    currentStepIndex: 0,
    steps: [1, 2, 3]
  }),
  computed: {
    ...mapState(useAssessmentStore, { assessment: 'activeAssessment' }),
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    isCoachedCallViaAssess() {
      let isCoachedCallViaAssess = false;
      const evaluationId = this.$route.query.evaluationId;
      if (evaluationId) {
        isCoachedCallViaAssess = true;
      }

      return isCoachedCallViaAssess;
    }
  },
  created() {
    if (this.assessment) {
      // There should never already be an active assessment when we mount this component. Clear it out before moving on.
      this.cancelAssessment();
    }
  },
  mounted() {
    this.updateDocTitle('Assessment');
    const draftId = this.$route.query.draftId;

    this.beginAssessment(Number(this.$route.params.agentId), draftId ? Number(draftId) : undefined);
    window.addEventListener('beforeunload', this.onBeforeUnload);
  },
  methods: {
    ...mapActions(useDocStore, ['updateDocTitle']),
    ...mapActions(useAssessmentStore, ['beginAssessment', 'saveAssessment', 'cancelAssessment']),
    ...mapActions(useDashboardStore, ['hydrateDashboard']),
    ...mapActions(useConfirmDialogStore, { routeLeaveConfirm: 'open' }),
    back() {
      // If Assessment was launched via Assess, return to original Assess page (history managed outside of router)
      if (this.isCoachedCallViaAssess) {
        window.history.back();
      } else {
        this.currentStepIndex === 0 ? this.$router.back() : this.currentStepIndex--;
      }
    },
    saveAsDraft() {
      this.complete(true);
    },
    next(event) {
      this.currentStepIndex === this.steps.length - 1 ? this.complete(event) : this.currentStepIndex++;
    },
    complete(draft) {
      this.saveAssessment(draft)
        .then(() => {
          this.hydrateDashboard();
          this.$router.push({ name: 'ManagerDash' });
        })
        .catch(error => {
          this.notify.error(error);
        });
    },
    async checkForUnsavedChanges() {
      let continueNav = true;
      // Only attempt to confirm navigation if there is an active assessment.
      // Otherwise, just do it without confirmation.
      if (this.assessment) {
        const confirmed = await this.routeLeaveConfirm(
          this.assessment.coachingSessionId ? UNDO_DRAFT_CHANGES_CONFIRMATION : CANCEL_ASSESSMENT_CONFIRMATION
        );
        continueNav = confirmed;
      }
      return continueNav;
    },
    async onBeforeUnload(event) {
      let continueNav = false;
      if (this.assessment) {
        continueNav = true;
      }

      (event || window.event).returnValue = continueNav;
      return continueNav;
    }
  },
  async beforeRouteLeave() {
    return await this.checkForUnsavedChanges();
  },
  unmounted() {
    this.cancelAssessment();
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }
};
</script>

<style lang="scss">
.assessment {
  min-height: calc(100vh - var(--app-header-height));
  background-color: var(--assess-bg-light);

  &__container {
    text-align: center;
    padding: 48px 0;
  }
}
</style>
